import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';

const VideoGrid = () => {
  const [videos, setVideos] = useState([]);
  const API_KEY = 'AIzaSyBRd_8kCzTFwJ0nc9kZhJrjhmdycirn7s0'; // Reemplaza con tu API Key

  // Memoriza los playlists para evitar recrearlos en cada render
  const playlists = useMemo(() => ({
    productividad: 'PLm6JN36t1vNzC0KLk6htDnG9xXrzyO7pd',  // ID de la lista de reproducción de Productividad
    habitos: 'PLm6JN36t1vNzznmS8I8Id9qg4arWxAnbf',       // ID de la lista de reproducción de Hábitos
    estiloVida: 'PLm6JN36t1vNyj0mFFwVvHCYJoljrU5HZx',    // ID de la lista de reproducción de Estilo de Vida
  }), []);

  useEffect(() => {
    const fetchVideosFromPlaylists = async () => {
      try {
        const playlistPromises = Object.values(playlists).map(async (playlistId) => {
          const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=5&playlistId=${playlistId}&key=${API_KEY}`
          );
          return response.data.items;
        });

        const playlistVideos = await Promise.all(playlistPromises);
        const allVideos = playlistVideos.flat();
        setVideos(allVideos);
      } catch (error) {
        console.error('Error al obtener los videos de las listas de reproducción:', error);
      }
    };

    fetchVideosFromPlaylists();
  }, [API_KEY, playlists]);

  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 3; // Mostrar 3 videos a la vez

  // Función para ir al siguiente video
  const nextSlide = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % videos.length); // Avanzamos de manera circular
  };

  // Función para ir al video anterior
  const prevSlide = () => {
    setStartIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length); // Retrocedemos de manera circular
  };

  // Obtener los videos que se deben mostrar en la vista actual
  const displayedVideos = Array.from({ length: itemsToShow }, (_, i) => videos[(startIndex + i) % videos.length]);

  return (
    <div className="relative max-w-[90%] mx-auto overflow-hidden">
      <h2 className="text-center text-2xl font-montserrat mb-6">Mis Videos</h2>

      {/* Contenedor del grid */}
      <div className="flex items-center">
        {/* Flecha Izquierda */}
        <button
          onClick={prevSlide}
          className="absolute left-0 z-10 bg-gray-200 rounded-full p-2 hover:bg-gray-400 transition duration-300"
        >
          &#8592;
        </button>

        {/* Contenedor de videos */}
        <div className="flex w-full transition-transform duration-300">
          {displayedVideos.map((video, index) => (
            video && video.snippet ? ( // Verificación de video y snippet
              <div key={index} className="w-[33.33%] p-4 flex-shrink-0">
                <div className="bg-white shadow-lg rounded-lg p-4 hover:shadow-2xl transition duration-300">
                  <div className="relative mb-4">
                    {video.snippet.thumbnails?.high ? (
                      <img
                        src={video.snippet.thumbnails.high.url}
                        alt={video.snippet.title}
                        className="w-full h-auto rounded-lg"
                      />
                    ) : (
                      <p className="text-center text-gray-500">No hay miniatura disponible</p>
                    )}
                  </div>
                  <h3 className="text-lg font-montserrat text-gray-800 mb-2 text-center">
                    {video.snippet.title}
                  </h3>
                  <p className="text-gray-600 text-sm mb-4 text-center">
                    {video.snippet.description ? video.snippet.description.substring(0, 100) : 'No hay descripción disponible'}...
                  </p>
                  <a
                    href={`https://www.youtube.com/watch?v=${video.snippet.resourceId?.videoId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300 font-montserrat text-[1rem] block text-center"
                  >
                    Ver en YouTube
                  </a>
                </div>
              </div>
            ) : null // Solo renderizar si hay video y snippet
          ))}
        </div>

        {/* Flecha Derecha */}
        <button
          onClick={nextSlide}
          className="absolute right-0 z-10 bg-gray-200 rounded-full p-2 hover:bg-gray-400 transition duration-300"
        >
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default VideoGrid;