const BioSection = () => (
  <section id="bio-section" className="flex flex-col justify-center items-center p-8 pb-12 bg-white min-h-[80vh] xs:p-3 xs:pb-6 sm-xs:p-4 sm-xs:pb-8 md-xs:p-5 md-xs:pb-7 sm:p-6 sm:pb-10 md:p-7 md:pb-12 lg:p-8 lg:pb-14 xl:p-10 xl:pb-16">
    <div className="max-w-[90%] mx-auto flex flex-col md:flex-row justify-center items-center w-full">
      <div className="flex flex-col md:flex-row items-center justify-center w-full">
        {/* Contenedor de texto (columna izquierda) */}
        <div className="md:w-9/12 lg:w-8/12 xl:w-7/12">
          {/* Párrafo 1 con Imagen 1 */}
          <div className="flex flex-col justify-center items-center mb-8 xs:mb-4 xs:mt-8 sm-xs:mb-4 sm-xs:mt-6 md-xs:mb-5 md-xs:mt-7 sm:mb-6 sm:mt-8 md:mb-7 md:mt-9 lg:mb-8 lg:mt-10 xl:mb-10 xl:mt-12">
            <p className="text-lg font-montserrat mb-4 md:mb-4 md:w-3/4 lg:w-full xl:w-[85%] text-left xs:text-[0.85rem] xs:text-center xs:w-full sm-xs:text-[0.9rem] sm-xs:text-center sm-xs:w-full md-xs:text-[0.95rem] sm:text-[1rem] md:text-[1.1rem] lg:text-[1.2rem] xl:text-[1.3rem]">
              He construido un estilo de vida basado en <span className="bg-custom-orange px-1">hábitos saludables</span> que potencian mi bienestar diario, y he progresado en mi carrera como YouTuber y Programador, siempre buscando el <span className="bg-custom-orange px-1">crecimiento constante.</span>
            </p>
            <img
              src="/imgs/Germán-Estilo-de-Vida.png"
              alt="Imagen 1"
              className="w-full xs:w-[80%] sm-xs:w-[80%] h-auto rounded-lg shadow-lg ml-4 xs:ml-0 xs:mt-4 sm-xs:ml-0 sm-xs:mt-4 md-xs:w-[75%] md-xs:ml-2 sm:w-[70%] sm:ml-3 md:w-[65%] md:ml-4 lg:w-[60%] lg:ml-4 xl:w-[55%] xl:ml-4" 
            />
          </div>

          {/* Párrafo 2 con Imagen 2 */}
          <div className="flex flex-col justify-center items-center mb-8 xs:mb-4 xs:mt-8 sm-xs:mb-4 sm-xs:mt-6 md-xs:mb-5 md-xs:mt-7 sm:mb-6 sm:mt-8 md:mb-7 md:mt-9 lg:mb-8 lg:mt-10 xl:mb-10 xl:mt-12">
            <p className="text-lg font-montserrat mb-4 md:mb-4 md:w-3/4 lg:w-full xl:w-[85%] text-left xs:text-[0.85rem] xs:text-center xs:w-full sm-xs:text-[0.9rem] sm-xs:text-center sm-xs:w-full md-xs:text-[0.95rem] sm:text-[1rem] md:text-[1.1rem] lg:text-[1.2rem] xl:text-[1.3rem]">
              Un momento decisivo en mi vida fue el cambio radical de un bachillerato social al mundo de las <span className="relative inline-block before:content-[''] before:absolute before:left-0 before:bottom-1 before:h-[2px] before:w-full before:bg-custom-light-orange">ingenierías</span>, una decisión que me retó y me ayudó a descubrir nuevas pasiones.
            </p>
            <img
              src="/imgs/Germán-Universidad.png"
              alt="Imagen 2"
              className="w-full xs:w-[80%] sm-xs:w-[80%] h-auto rounded-lg shadow-lg ml-4 xs:ml-0 xs:mt-4 sm-xs:ml-0 sm-xs:mt-4 md-xs:w-[75%] md-xs:ml-2 sm:w-[70%] sm:ml-3 md:w-[65%] md:ml-4 lg:w-[60%] lg:ml-4 xl:w-[55%] xl:ml-4" 
            />
          </div>

          {/* Párrafo 3 con Imagen 3 */}
          <div className="flex flex-col justify-center items-center mb-8 xs:mb-4 xs:mt-8 sm-xs:mb-4 sm-xs:mt-6 md-xs:mb-5 md-xs:mt-7 sm:mb-6 sm:mt-8 md:mb-7 md:mt-9 lg:mb-8 lg:mt-10 xl:mb-10 xl:mt-12">
            <p className="text-lg font-montserrat mb-4 md:mb-4 md:w-3/4 lg:w-full xl:w-[85%] text-left xs:text-[0.85rem] xs:text-center xs:w-full sm-xs:text-[0.9rem] sm-xs:text-center sm-xs:w-full md-xs:text-[0.95rem] sm:text-[1rem] md:text-[1.1rem] lg:text-[1.2rem] xl:text-[1.3rem]">
              También tuve la suerte de vivir en <span className="relative inline-block before:content-[''] before:absolute before:left-0 before:bottom-1 before:h-[2px] before:w-full before:bg-custom-light-orange">Italia</span> durante seis meses como estudiante Erasmus, donde me sumergí en la cultura italiana mientras perfeccionaba mi inglés.
            </p>
            <img
              src="/imgs/Germán-Italia.png"
              alt="Imagen 3"
              className="w-full md:w-[65%] xs:w-[80%] sm-xs:w-[80%] h-auto rounded-lg shadow-lg ml-4 xs:ml-0 xs:mt-4 sm-xs:ml-0 sm-xs:mt-4 md-xs:w-[75%] md-xs:ml-2 sm:w-[70%] sm:ml-3 md:ml-4 lg:w-[60%] lg:ml-4 xl:w-[55%] xl:ml-4"
            />
          </div>

          {/* Párrafo 4 sin Imagen */}
          <p className="mt-14 mb-3 text-[0.9915rem] md:text-[1.3rem] xs:text-[0.85rem] mb:text-[1.4865rem] md-xs:text-[1.1rem] sm:text-[1.2rem] lg:text-[1.3rem] xl:text-[1.4rem] font-montserrat text-center xs:mt-12 xs:mb-5 sm-xs:mt-12 sm-xs:mb-5 md-xs:mt-10 md-xs:mb-6 sm:mt-14 sm:mb-7 md:mt-16 md:mb-8 lg:mt-20 lg:mb-10 xl:mt-22 xl:mb-12">
            Todas estas experiencias me han permitido forjar una base sólida en <span className="bg-custom-orange px-1">productividad</span>, <span className="bg-custom-orange px-1">marca personal</span> y <span className="bg-custom-orange px-1">desarrollo web</span>, que son los pilares sobre los que estoy construyendo mi carrera y mi futuro.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default BioSection;