import React from 'react';

const Testimonials = () => {
  return (
    <div className="max-w-[90%] mx-auto text-center mb-12">
      <h2 className="text-[2rem] font-montserrat font-semibold text-gray-800 mb-6">Testimonios</h2>
      <p className="text-gray-600 text-[1rem] mb-4">"Gracias a tus consejos de productividad, he mejorado mi planificación semanal."</p>
      <p className="text-gray-600 text-[1rem] mb-4">"Los tutoriales de programación son fáciles de seguir y muy útiles."</p>
      <p className="text-gray-600 text-[1rem] mb-4">"He aprendido a organizar mi vida de una manera más eficiente gracias a tus videos."</p>
    </div>
  );
};

export default Testimonials;
