import React from 'react';

const FinalCallToAction = () => {
  return (
    <div className="text-center mt-16">
      <p className="text-[1.2rem] font-montserrat text-gray-800 mb-6">
        ¿Te gusta mi contenido? Suscríbete a mi canal para no perderte los próximos videos.
      </p>
      <a
        href="https://www.youtube.com/tu-canal"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300 font-montserrat text-[1.1rem]"
      >
        Suscribirse
      </a>
    </div>
  );
};

export default FinalCallToAction;