const WelcomeSection = () => (
  <section className="flex flex-col justify-center items-center p-8 bg-blue-100 min-h-[90vh] xs:p-4 md-xs:p-6 sm:p-8 lg:p-12 xl:p-16">
    <div className="max-w-[90%] mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between lg:justify-end xl:justify-between xs:mt-5">
      {/* Contenedor de texto */}
      <div className="md:w-1/2 text-center md:text-left flex flex-col items-center md:items-start lg:-ml-4 xl:ml-0">
        <h2 className="text-[1.983rem] md:text-[3rem] sm-xs:text-[1.8rem] xs:text-[1.6rem] md-xs:text-[2rem] sm:text-[2.5rem] lg:text-[3.2rem] xl:text-[5.8rem] mb-2 font-montserrat">Hola! Soy</h2>
        <h2 className="text-[1.983rem] md:text-[5rem] sm-xs:text-[1.8rem] xs:text-[1.6rem] md-xs:text-[2rem] sm:text-[2.5rem] lg:text-[5.5rem] xl:text-[8.5rem] mb-2 font-montserrat">Germán</h2>
        <p className="text-[1.4853rem] md:text-[1.8rem] sm-xs:text-[1.2rem] xs:text-[1rem] md-xs:text-[1.4rem] sm:text-[1.6rem] lg:text-[1.8rem] xl:text-[4.2rem] font-montserrat">Programador, YouTuber y apasionado de los hábitos y la productividad.</p>
        <p className="mt-4 text-[0.9915rem] md:text-[1.5rem] sm-xs:text-[1rem] xs:text-[0.9rem] md-xs:text-[1.1rem] sm:text-[1.3rem] lg:text-[1.5rem] xl:text-[2.8rem] font-montserrat">Vamos a aprovechar mejor nuestro tiempo y disfrutar de lo que nos apasiona sin sacrificar nuestra vida personal. :)</p>

        {/* Flecha indicando que hay más contenido */}
        <div className="mt-8 flex justify-center w-full">
          <a href="#help-section" onClick={(e) => smoothScroll(e)}>
            <svg className="w-8 h-8 sm-xs:w-6 sm-xs:h-6 xs:w-5 xs:h-5 md-xs:w-7 md-xs:h-7 sm:w-8 sm:h-8 md:w-9 md:h-9 lg:w-10 lg:h-10 xl:w-14 xl:h-14 animate-bounce" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </a>
        </div>
      </div>

      {/* Imagen */}
      <img src="/imgs/ImagenComunicandoDefinitivaBannerCanal.png" alt="Germán sonriendo" className="w-3/5 md:w-[45%] sm-xs:w-4/5 xs:w-[85%] md-xs:w-3/4 sm:w-[70%] lg:w-[50%] lg:ml-12 xl:w-[45%] xl:ml-16 rounded-lg border-2 border-gray-300 shadow-lg mt-6 md:mt-0" />
    </div>
  </section>
);

const smoothScroll = (e) => {
  e.preventDefault();
  const target = document.querySelector(e.currentTarget.getAttribute("href"));
  window.scrollTo({
    top: target.offsetTop,
    behavior: "smooth",
  });
};

export default WelcomeSection;
