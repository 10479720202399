// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBfFIRQIaug3-CjX-BVlq3ZFQuaSgzNdzU",
  authDomain: "marca-personal-german-yt.firebaseapp.com",
  projectId: "marca-personal-german-yt",
  storageBucket: "marca-personal-german-yt.appspot.com",
  messagingSenderId: "1066562795232",
  appId: "1:1066562795232:web:3bfe07ec57a9c6dc0ac285",
  measurementId: "G-927DT2J1FT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
