import React from 'react';
import LetsTalk from './LetsTalkSection';
import Contact from './ContactSection';
import ContactInfo from './ContactInfoSection'; // Importamos la nueva sección

const MainContactLayoutSection = () => {
  return (
    <section className="flex flex-col justify-start items-start bg-blue-100">
      {/* Sección Hablemos y Formulario de Contacto en horizontal */}
      <div className="flex flex-col md:flex-row w-full justify-between space-y-8 md:space-y-0 min-h-[80vh]">
        {/* Sección Hablemos */}
        <div className="w-full md:w-1/2">
          <LetsTalk />
        </div>

        {/* Sección Formulario de Contacto */}
        <div className="w-full md:w-1/2">
          <Contact />
        </div>
      </div>

      {/* Nueva Sección de Información de Contacto Alternativa */}
      <div className="w-full bg-white min-h-[80vh] flex items-center">
        <ContactInfo />
      </div>
    </section>
  );
};

export default MainContactLayoutSection;