import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';        // Importa el componente Home
//import ServicesFISection from './components/ServicesFISection'; // Importa el componente Services
import Messages from './components/Messages'; // Importa el componente Messages
import Header from './components/Header';
import WelcomeSection from './components/WelcomeSection';
import HelpSection from './components/HelpSection';
import Footer from './components/Footer';  // Importa el Footer
import AboutMeSection from './components/AboutMeSection';
import BioSection from './components/BioSection';
import MissionSection from './components/MissionSection';
import ConsultingSection from './components/ConsultingSection';
import ProductDigitalSection from './components/ProductDigitalSection';
//import ServicesDetailsSection from './components/ServicesDetailsSection';
import MainContactLayoutSection from './components/MainContactLayoutSection';
import UnderConstructionSection from './components/UnderConstructionSection';
import YouTubePage from './components/YouTubePage';  // Importa la página de YouTube


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          {/* Página principal con las secciones */}
          <Route path="/" element={
            <>
              <WelcomeSection />
              <HelpSection />
              <Footer />
            </>
          } />
          {/* Otras rutas */}
          <Route path="/home" element={<Home />} />
          {/*<Route path="/services" element={<><ServicesFISection /><ServicesDetailsSection /><Footer /></>} />*/}
          <Route path="/contact" element={<><MainContactLayoutSection/><Footer /></>} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/about" element={<><AboutMeSection /> <BioSection /> <MissionSection /><Footer /></>} />

           {/* Nuevas rutas para las secciones "en desarrollo" */}
           <Route path="/services" element={<><UnderConstructionSection sectionName="Servicios" /><Footer /></>} />
          <Route path="/portfolio" element={<><UnderConstructionSection sectionName="Portafolio" /><Footer /></>} />

          {/* Ruta de YouTube */}
          <Route path="/youtube" element={<><YouTubePage /><Footer /></>} />  {/* Añadida la ruta de YouTube */}

          {/* Nuevas rutas para los servicios específicos */}
          <Route path="/consulting" element={<ConsultingSection />} />
          <Route path="/product-digital" element={<ProductDigitalSection />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
