import React from 'react';
import YouTubeHeader from './YouTubeHeader';
import LatestYouTubeVideo from './LatestYouTubeVideo';
import VideoGrid from './VideoGrid';
import Testimonials from './Testimonials'; // Opcional
import FinalCallToAction from './FinalCallToAction';

const YouTubePage = () => {
  return (
    <section className="min-h-[100vh] bg-gray-100 p-8">
      {/* Header de la página */}
      <YouTubeHeader />

      {/* Video Destacado (Opcional) */}
      <LatestYouTubeVideo />

      {/* Grid de Videos */}
      <VideoGrid />

      {/* Testimonios (Opcional) */}
      <Testimonials />

      {/* Llamada a la acción final */}
      <FinalCallToAction />
    </section>
  );
};

export default YouTubePage;