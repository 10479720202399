const Footer = () => (
  <footer className="bg-gray-800 text-white p-4 w-full mt-16">
    <div className="max-w-[90%] mx-auto flex flex-col md:flex-row justify-between items-center">
      {/* Información básica */}
      <div className="mb-4 md:mb-0">
        <p className="text-base md:text-lg xl:text-xl font-montserrat sm-xs:text-center xs:text-center">
          © 2024 Germán Hernández Mairal. Todos los derechos reservados.
        </p>
      </div>
      {/* Enlaces a redes sociales */}
      <div className="flex space-x-6">
        <a href="https://www.linkedin.com/in/germ%C3%A1n-hern%C3%A1ndez-mairal-7584741ab/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
          <svg className="w-6 h-6 xl:w-8 xl:h-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-2v-9h2v9zm-1-10.328c-.686 0-1.241-.556-1.241-1.242 0-.686.555-1.242 1.241-1.242.686 0 1.241.556 1.241 1.242 0 .686-.555 1.242-1.241 1.242zm10.328 10.328h-2v-4.539c0-1.081-.02-2.471-1.506-2.471-1.506 0-1.737 1.178-1.737 2.395v4.615h-2v-9h1.924v1.229h.028c.268-.508 1.006-1.042 2.07-1.042 2.213 0 2.621 1.456 2.621 3.35v5.463z"/>
          </svg>
        </a>
        <a href="https://youtube.com/@germanhernandezmairal?si=imzk90LeYb1KpkyL" target="_blank" rel="noopener noreferrer" className="hover:text-red-400">
          <svg className="w-6 h-6 xl:w-8 xl:h-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.499 6.186c-.27-1.022-1.065-1.821-2.086-2.09-1.842-.51-9.413-.51-9.413-.51s-7.571 0-9.414.51c-1.021.269-1.816 1.068-2.086 2.09-.507 1.839-.507 5.684-.507 5.684s0 3.845.507 5.684c.27 1.022 1.065 1.821 2.086 2.09 1.843.51 9.414.51 9.414.51s7.571 0 9.413-.51c1.021-.269 1.816-1.068 2.086-2.09.507-1.839.507-5.684.507-5.684s0-3.845-.507-5.684zm-14.496 8.114v-6.316l6.145 3.158-6.145 3.158z"/>
          </svg>
        </a>
        <a href="https://www.instagram.com/germanhernandezmairal/" target="_blank" rel="noopener noreferrer" className="hover:text-pink-400">
          <svg className="w-6 h-6 xl:w-8 xl:h-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2.163c3.204 0 3.584.012 4.849.07 1.366.062 2.633.334 3.608 1.309.975.975 1.248 2.242 1.309 3.608.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.062 1.366-.334 2.633-1.309 3.608-.975.975-2.242 1.248-3.608 1.309-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.366-.062-2.633-.334-3.608-1.309-.975-.975-1.248-2.242-1.309-3.608-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.062-1.366.334-2.633 1.309-3.608.975-.975 2.242-1.248 3.608-1.309 1.265-.058 1.645-.07 4.849-.07zm0-2.163c-3.271 0-3.667.012-4.947.072-1.623.074-3.064.354-4.262 1.552-1.198 1.198-1.478 2.639-1.552 4.262-.059 1.28-.072 1.676-.072 4.947s.012 3.667.072 4.947c.074 1.623.354 3.064 1.552 4.262 1.198 1.198 2.639 1.478 4.262 1.552 1.28.059 1.676.072 4.947.072s3.667-.012 4.947-.072c1.623-.074 3.064-.354 4.262-1.552 1.198-1.198 1.478-2.639 1.552-4.262.059-1.28.072-1.676.072-4.947s-.012-3.667-.072-4.947c-.074-1.623-.354-3.064-1.552-4.262-1.198-1.198-2.639-1.478-4.262-1.552-1.28-.059-1.676-.072-4.947-.072zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.162 6.162 6.162 6.162-2.759 6.162-6.162-2.759-6.162-6.162-6.162zm0 10.162c-2.208 0-4-1.792-4-4s1.792-4 4-4 4 1.792 4 4-1.792 4-4 4zm6.406-11.845c-.796 0-1.441.646-1.441 1.441s.646 1.441 1.441 1.441 1.441-.646 1.441-1.441-.646-1.441-1.441-1.441z"/>
          </svg>
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;