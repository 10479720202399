import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../services/firebase.js'; // Asegúrate de importar el `db`

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false); // Para el mensaje de confirmación
  const [error, setError] = useState(''); // Para manejar errores
  const [rows, setRows] = useState(6); // Estado para el número de filas

  // Efecto para ajustar el valor de rows según el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) { // Pantallas xl (1280px o más)
        setRows(10); // Aumenta el número de filas en pantallas grandes
      } else {
        setRows(6); // Valor por defecto para pantallas más pequeñas
      }
    };

    // Ajustar el valor de rows al cargar y al cambiar el tamaño de la ventana
    handleResize();
    window.addEventListener('resize', handleResize);

    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Limpiar el estado de error en cada envío
    try {
      // Crea una referencia a la colección "messages"
      const docRef = await addDoc(collection(db, 'messages'), {
        name,
        email,
        subject,
        message,
        timestamp: new Date(),
      });
      console.log('Documento añadido con ID: ', docRef.id);
      setFormSubmitted(true); // Establece el estado como enviado
      setName(''); // Limpiar los campos después de enviar el formulario
      setEmail('');
      setSubject('');
      setMessage('');
    } catch (e) {
      console.error('Error al agregar el documento: ', e);
      setError('Ocurrió un error al enviar el mensaje. Por favor, intenta de nuevo.'); // Mensaje de error
    }
  };

  return (
    <section id="contactForm" className="flex flex-col justify-center p-8 md-xs:pt-0 sm:pb-20 bg-blue-100 min-h-[30vh]">
      <div className="w-full max-w-[1200px] xl:max-w-[1400px] mx-auto mt-[60px] md:mt-[110px] sm:mt-[20px] md-xs:mt-14 sm-xs:-mt-6 xs:-mt-6">

        {formSubmitted && (
          <p className="text-green-500 mb-6 font-montserrat">
            ¡Gracias por contactarme! Te responderé pronto.
          </p>
        )}

        {error && (
          <p className="text-red-500 mb-6 font-montserrat">
            {error}
          </p>
        )}

        <form onSubmit={handleSubmit} className="w-full max-w-lg xl:max-w-4xl xl:min-h-[60vh] mx-auto bg-white xl:p-10 xl:py-12 p-6 rounded-lg shadow-lg">
          {/* Input para Nombre */}
          <div className="mb-4 xl:mb-6">
            <input
              type="text"
              placeholder="Nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 xl:px-6 xl:py-4 xl:text-[1.25rem] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Input para Correo */}
          <div className="mb-4 xl:mb-6">
            <input
              type="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 xl:px-6 xl:py-4  xl:text-[1.25rem] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Input para Asunto */}
          <div className="mb-4 xl:mb-6">
            <input
              type="text"
              placeholder="Asunto"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full px-4 py-2 xl:px-6 xl:py-4  xl:text-[1.25rem] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Textarea para Mensaje */}
          <div className="mb-4 xl:mb-6">
            <textarea
              placeholder="Mensaje"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-4 py-2 xl:px-6 xl:py-4 xl:text-[1.25rem] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={rows} // Cambia dinámicamente según el tamaño de la pantalla
              required
            />
          </div>

          {/* Botón de Enviar */}
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 xl:py-4 xl:text-[1.5rem] rounded-lg font-montserrat hover:bg-blue-600 transition duration-300 w-full"
          >
            Enviar Mensaje
          </button>
        </form>
      </div>
    </section>
  );
}

export default Contact;