import React from 'react';

const LetsTalk = () => {
  return (
    <section className="flex flex-col justify-start items-start p-8 bg-blue-100 min-h-[50vh]">
      {/* Ajustes para diferentes tamaños de pantalla */}
      <div className="ml-[72px] mt-[61px] md:ml-[48px] md:mt-[61px] md-xs:ml-[20px] md-xs:-mb-48 lg:ml-[71px] lg:mt-[61px] sm:ml-[42px] sm:mt-[60px] sm:-mb-20 max-w-[90%] text-left xl:ml-[136px] xl:mt-[126px] sm-xs:ml-6 xs:ml-4">
        {/* Título Principal */}
        <h2 className="text-[1.983rem] md:text-[2.9715rem] font-montserrat xl:text-[4rem] mb-4">
          Hablemos
        </h2>

        {/* Introducción Breve */}
        <p className="text-left mt-[31px] md:mt-[20px] sm:mt-[30px] xl:mt-[40px] text-[1rem] md:text-[1.4865rem] xl:text-[2rem] font-montserrat mb-6 sm:max-w-[470px] max-w-[600px] lg:mt-[33px] lg:max-w-[700px] xl:max-w-[900px] xs:text-center sm:text-left md:text-left lg:text-left xl:text-left xs:-ml-1">
          <span>¿Tienes algún proyecto en mente o quieres saber más sobre cómo puedo ayudarte?</span>
          <br />
          <span className="mt-4 md:mt-[10px] sm:mt-[10px] inline-block">No dudes en enviarme un mensaje.</span>
          <br />
          <span className="mt-4 md:mt-[10px] sm:mt-[10px] inline-block">¡Estaré encantado de hablar contigo!</span>
        </p>
      </div>
    </section>
  );
};

export default LetsTalk;
