import React from 'react';

const YouTubeHeader = () => {
  return (
    <div className="text-center mb-10">
      <h1 className="text-[2.5rem] font-montserrat font-bold text-gray-800 mb-4">Mi Contenido en YouTube</h1>
      <p className="text-[1.2rem] font-montserrat text-gray-600 mb-6">
        En mi canal de YouTube comparto hábitos, aprendizajes y experiencias para inspirar a los demás a mejorar sus vidas. 
      </p>
      <a
        href="https://www.youtube.com/@germanhernandezmairal"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300 font-montserrat text-[1.1rem]"
      >
        Visita mi canal
      </a>
    </div>
  );
};

export default YouTubeHeader;
