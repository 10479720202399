const UnderConstructionSection = ({ sectionName }) => (
  <section className="flex flex-col justify-center items-center min-h-[80vh] bg-blue-100 p-8 text-center -mb-16">
    <div className="max-w-[90%] mx-auto">
      {/* Título principal */}
      <h2 className="text-[2.5rem] font-montserrat text-gray-800 mb-8
                    xs:text-[1.5rem] sm:text-[2rem] md:text-[2.3rem] lg:text-[2.8rem] xl:text-[3rem]">
        {sectionName} en Desarrollo
      </h2>

      {/* Texto 1 */}
      <p className="text-[1.5rem] font-montserrat text-gray-600 mb-10
                    xs:text-[1rem] sm:text-[1.2rem] md:text-[1.4rem] lg:text-[1.6rem] xl:text-[1.8rem]">
        Esta sección está en desarrollo, pero pronto tendrás acceso a contenido nuevo. ¡Mantente atento!
      </p>

      {/* Texto 2 */}
      <p className="text-[1.2rem] font-montserrat text-gray-500 mb-12
                    xs:text-[0.9rem] sm:text-[1rem] md:text-[1.1rem] lg:text-[1.3rem] xl:text-[1.4rem]">
        Estoy trabajando para traerte los mejores servicios y mostrarte un portafolio lleno de proyectos únicos. ¡Pronto disponible!
      </p>

      {/* Imagen comentada en caso de ser usada */}
      {/*<img
        src="/imgs/under-construction.png"
        alt="Sección en desarrollo"
        className="w-[60%] md:w-[40%] lg:w-[30%] xl:w-[25%] mx-auto mb-8"
      />*/}

      {/* Texto 3 */}
      <p className="text-[1rem] font-montserrat text-gray-500
                    xs:text-[0.8rem] sm:text-[0.9rem] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem]">
        Mientras tanto, puedes explorar otras secciones del sitio o contactarme para obtener más información.
      </p>
    </div>
  </section>
);

export default UnderConstructionSection;