import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LatestYouTubeVideo = () => {
  const [latestVideo, setLatestVideo] = useState(null);
  const API_KEY = 'AIzaSyBRd_8kCzTFwJ0nc9kZhJrjhmdycirn7s0'; // Reemplaza con tu API Key
  const CHANNEL_ID = 'UChOX2hNwUkodn1k9Y3xt_mg'; // Reemplaza con tu ID de canal

  useEffect(() => {
    const fetchLatestLongFormVideo = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&type=video&channelId=${CHANNEL_ID}&maxResults=5&key=${API_KEY}`
        );

        if (response.data.items && response.data.items.length > 0) {
          for (const video of response.data.items) {
            const videoId = video.id.videoId;

            if (!videoId) continue;

            const videoDetailsResponse = await axios.get(
              `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${API_KEY}`
            );

            if (videoDetailsResponse.data.items && videoDetailsResponse.data.items.length > 0) {
              const duration = videoDetailsResponse.data.items[0].contentDetails.duration;
              const durationInSeconds = convertDurationToSeconds(duration);

              if (durationInSeconds > 180) {
                setLatestVideo(video);
                break;
              }
            }
          }
        }
      } catch (error) {
        console.error('Error al obtener el último video en formato largo:', error);
      }
    };

    fetchLatestLongFormVideo();
  }, [CHANNEL_ID, API_KEY]);

  const convertDurationToSeconds = (duration) => {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    const hours = match[1] ? parseInt(match[1].replace('H', '')) : 0;
    const minutes = match[2] ? parseInt(match[2].replace('M', '')) : 0;
    const seconds = match[3] ? parseInt(match[3].replace('S', '')) : 0;
    return hours * 3600 + minutes * 60 + seconds;
  };

  if (!latestVideo) {
    return <p className="text-center">Cargando el último video...</p>;
  }

  const videoId = latestVideo.id.videoId;
  const videoTitle = latestVideo.snippet.title;
  let videoDescription = latestVideo.snippet.description;

  // Utilizamos una expresión regular más flexible para eliminar cualquier texto desde "Donde encontrarme"
  const cutOffPoint = videoDescription.search(/donde\s*encontrarme/i);
  if (cutOffPoint !== -1) {
    videoDescription = videoDescription.substring(0, cutOffPoint).trim();
  }

  return (
    <div className="mt-12 mb-12 flex flex-col items-center"> {/* Margen superior e inferior */}
      <h2 className="text-2xl font-montserrat mb-6">Último Video Subido</h2> {/* Header de la sección */}
      
      {/* Contenedor del video */}
      <div className="relative" style={{ width: '72%', paddingBottom: '40%', position: 'relative' }}> {/* Proporción 16:9 manual */}
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="Último video"
          className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      {/* Título del video */}
      <h3 className="text-xl font-montserrat mt-8 mb-4 text-center">{videoTitle}</h3>

      {/* Descripción del video */}
      <p className="text-md font-montserrat text-gray-600 text-center px-4 sm:px-8 lg:px-16">{videoDescription}</p>
    </div>
  );
};

export default LatestYouTubeVideo;