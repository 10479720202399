import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase.js'; // Asegúrate de importar el `db`


// Ejemplo: Leer documentos de una colección

function Messages() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      const querySnapshot = await getDocs(collection(db, 'messages'));
      const messagesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesList);
    };

    fetchMessages();
  }, []);

  return (
    <div>
      <h1>Mensajes</h1>
      <ul>
        {messages.map((message) => (
          <li key={message.id}>
            <strong>{message.name}</strong>: {message.message}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Messages;